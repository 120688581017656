<template>
    <div>
        <header>
            <Nav/>
            <Alert/>
        </header>
        <main>
            <section class="d-flex flex-column justify-content-center m-2 p-4 text-center flex-wrap">
                <h1 class="" v-text="apiTexts['contact.heading']"></h1>
                <h4 class="mb-5" v-text="apiTexts['contact.subtitle']"></h4>

                <div class="contact-form-container m-auto d-flex flex-wrap " style="box-shadow: 0 1px 20px 0px #00000010 !important; border:none;">
                    <div class="contact-us col-lg-6 col-12" style="border:none;">
                        <div class="contact-header w-auto">
                            <h2 v-text="apiTexts['contact.contactform.title']"></h2>
                            <p class="mb-5" v-text="apiTexts['contact.contactform.paragraphtext']"></p>
                            <div>
                                <p><i class="fas fa-envelope mr-2"></i>{{apiTexts['contact.infos.mail']}}</p>
                                <p><i class="fas fa-map-marker-alt mr-2"></i>{{apiTexts['contact.infos.adress']}}</p>
                                <p><i class="fab fa-linkedin mr-2"></i><a target="_blank" :href="apiLinks['contact.contactform.linkedin']" v-text="apiTexts['contact.contactform.linkedin']"></a></p>
                            </div>
                        </div>
                    </div>
                    <div class="w-auto contact-form align-middle m-auto p-3 col-lg-6 col-12">
                        <form class="contact-form d-flex flex-column m-auto" autocomplete="off">
                            <span class="d-flex justify-content-between flex-wrap">
                                <div :class="errorMessages.firstname.display ? 'input-field mb-0 mt-4 col-12 p-0 error' : 'input-field mb-0 mt-4 col-12 p-0' ">
                                    <input v-model="mailData.firstname" id="first_name" type="text">
                                    <label for="first_name">Prénom</label>
                                </div>
                                <p style="font-size:.8em;" class="text-danger m-0 p-0" v-if="errorMessages.firstname.display" v-text="errorMessages.firstname.message"></p>

                                <div :class="errorMessages.lastname.display ? 'input-field mb-0 mt-4 col-12 p-0 error' : 'input-field mb-0 mt-4 col-12 p-0' ">
                                    <input v-model="mailData.lastname" id="last_name" type="text">
                                    <label for="last_name">Nom</label>
                                </div>
                                <p style="font-size:.8em;" class="text-danger m-0 p-0" v-if="errorMessages.lastname.display" v-text="errorMessages.lastname.message"></p>

                                <div :class="errorMessages.email.display ? 'input-field mb-0 mt-4 col-12 p-0 error' : 'input-field mb-0 mt-4 col-12 p-0' ">
                                    <input v-model="mailData.email" id="email" type="email">
                                    <label for="email">E-mail</label>
                                </div>
                                <p style="font-size:.8em;" class="text-danger m-0 p-0" v-if="errorMessages.email.display" v-text="errorMessages.email.message"></p>

                                <div :class="errorMessages.tel.display ? 'input-field mb-0 mt-4 col-12 p-0 error' : 'input-field mb-0 mt-4 col-12 p-0' ">
                                    <input v-model="mailData.tel" id="tel" type="number">
                                    <label for="tel">Téléphone</label>
                                </div>
                                <p style="font-size:.8em;" class="text-danger m-0 p-0" v-if="errorMessages.tel.display" v-text="errorMessages.tel.message"></p>

                                <div :class="errorMessages.message.display ? 'input-field mb-0 mt-4 col-12 p-0 error' : 'input-field mb-0 mt-4 col-12 p-0' ">
                                    <textarea v-model="mailData.message" id="message" class="materialize-textarea"></textarea>
                                    <label for="message">Message</label>
                                </div>
                                <p style="font-size:.8em;" class="text-danger m-0 p-0" v-if="errorMessages.message.display" v-text="errorMessages.message.message"></p>

                            </span>

                            <span class="d-flex justify-content-center flex-wrap my-3 mx-auto">
                                <label class="w-75 mr-0 mt-auto" for="autorisation" v-text="apiTexts['contact.contactform.autorisation']"></label>
                            </span>

                            <button class="btn-form btn-big btn-big-primary mx-auto my-3 text-white" v-text="apiTexts['contact.contactform.button.submit']" @click.prevent="sendMessage"></button>
                        </form>
                    </div>
                </div>
            </section>
        </main>
        <Footer/>
    </div>
</template>


<script>
    import apiHandler from '@/assets/scripts/apiHandler.js'
    import mailHandler from '@/assets/scripts/mailHandler.js'

    import Nav from "@/components/Nav.vue"
    import Footer from "@/components/Footer.vue"
    import Alert from "@/components/Alert.vue"
    
    export default {
        name: 'Contact',
        components: {
            Nav,
            Footer,
            Alert
        },
        data: ()=> {
        return {
            mailData: {
                to: "",
                message: "",
                lastname: "",
                firstname: "",
                tel: "",
                email: ""
            },
            errorMessages: {
                message: {
                    display: false,
                    message: ""
                },
                lastname: {
                    display: false,
                    message: ""
                },
                firstname: {
                    display: false,
                    message: ""
                },
                tel: {
                    display: false,
                    message: ""
                },
                email: {
                    display: false,
                    message: ""
                }
            },
            apiTexts: {},
            apiLinks: {}
            }
        },
        created() {
            apiHandler.getElements("texts", {page:"contact"})
            .then(res => {
                this.apiTexts = {}
                res.map( item => {
                    this.apiTexts[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
            
            apiHandler.getElements("links", {page:"contact"})
            .then(res => {
                this.apiLinks = {}
                res.map( item => {
                    this.apiLinks[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getParameters()
            .then(res => {
                this.mailData.to = res.contact_email
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
        },
        methods: {
            sendMessage: function(){
                let mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                let failed = false

                if(this.mailData.lastname == undefined || this.mailData.lastname == ""){
                    this.errorMessages.lastname.message = "Veuillez renseigner un nom"
                    this.errorMessages.lastname.display = true
                    failed = true
                }else{
                    this.errorMessages.lastname.display = false
                }

                if(this.mailData.firstname == undefined || this.mailData.firstname == ""){
                    this.errorMessages.firstname.message = "Veuillez renseigner un prénom"
                    this.errorMessages.firstname.display = true
                    failed = true
                }else{
                    this.errorMessages.firstname.display = false
                }

                if(this.mailData.email == undefined || this.mailData.email == ""){
                    this.errorMessages.email.message = "Veuillez renseigner une adresse mail"
                    this.errorMessages.email.display = true
                    failed = true
                }else if(!mailRegex.test(this.mailData.email)){
                    this.errorMessages.email.message = "Veuillez renseigner une adresse mail valide"
                    this.errorMessages.email.display = true
                    failed = true
                }else{
                    this.errorMessages.email.display = false
                }

                if(this.mailData.tel == undefined || this.mailData.tel == ""){
                    this.errorMessages.tel.message = "Veuillez renseigner un numéro de téléphone"
                    this.errorMessages.tel.display = true
                    failed = true
                }else if(this.mailData.tel.length != 10){
                    this.errorMessages.tel.message = "Veuillez renseigner un numéro de téléphone valide"
                    this.errorMessages.tel.display = true
                    failed = true
                }else{
                    this.errorMessages.tel.display = false
                }
                
                if(this.mailData.message == undefined || this.mailData.message == ""){
                    this.errorMessages.message.message = "Veuillez renseigner un message"
                    this.errorMessages.message.display = true
                    failed = true
                }else{
                    this.errorMessages.message.display = false
                }

                if(!failed){
                    window.location.href = mailHandler.getMailURL(this.mailData)
                }

            }
        },
    }
</script>
