<template>
    <nav class="d-flex justify-content-between m-2 px-4 py-0">
        <input type="checkbox" id="check">
            <label class=" my-auto" for="check">
                <i class="fas fa-bars fa-2x" id="open"></i>
            </label>  
        <router-link class="navbar-brand" v-if="apiLinks['nav.ludimation.link'] != undefined" :to="apiLinks['nav.ludimation.link']"><img id="logo-ludimation" src="/img/logo-ludimation.jpg" alt=""></router-link>
            <ul class="navbar-nav d-flex my-auto ml-auto">
                <input type="checkbox" id="check">
                <label for="check">
                    <i class="fas fa-times fa-2x" id="close"></i>
                </label> 
                <li class="nav-item">
                    <router-link class="nav-link" v-if="apiLinks['nav.about.link'] != undefined" :to="apiLinks['nav.about.link']" v-text="apiTexts['nav.about.link']"></router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" v-if="apiLinks['nav.actus.all.link'] != undefined" :to="apiLinks['nav.actus.all.link']" v-text="apiTexts['nav.actus.all.link']"></router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" v-if="apiLinks['nav.scenario.all.link'] != undefined" :to="apiLinks['nav.scenario.all.link']" v-text="apiTexts['nav.scenario.all.link']"></router-link>
                </li>
                <li class="nav-item">
                    <button class="btn-menu">
                        <router-link class="nav-link" v-if="apiLinks['nav.button.contact'] != undefined" :to="apiLinks['nav.button.contact']" v-text="apiTexts['nav.button.contact']"></router-link>
                    </button>
                </li>
            </ul>  
    </nav>
</template>

<script>

import apiHandler from '@/assets/scripts/apiHandler.js'

export default {
    name: "Nav",
    data: () => {
        return {
            apiTexts: {},
            apiLinks: {}
        }
    },
    created() {
        apiHandler.getElements("texts", {page:"nav"})
        .then(res => {
            this.apiTexts = {}
            res.map( item => {
                this.apiTexts[item.path]=item.value
            })
        })
        .catch(err => console.error("⚠ Cannot get data from api !"))
        
        apiHandler.getElements("links", {page:"nav"})
        .then(res => {
            this.apiLinks = {}
            res.map( item => {
                this.apiLinks[item.path]=item.value
            })
        })
        .catch(err => console.error("⚠ Cannot get data from api !"))
    },
};
</script>
