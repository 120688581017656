<template>
    <div>
        <header>
            <Nav />
            <Alert />
        </header>
        <main>
            <section class="mx-0 mb-2 px-0 pb-4">
                <div class="hero-scenario d-flex flex-column m-0 ">
                    <h1 class="align-middle mx-auto my-5" v-text="apiTexts['scenariosall.hero.heading']"></h1>
                    <h2 class="align-middle mx-auto my-4" v-text="apiTexts['scenariosall.hero.catchline']"></h2>
                </div>
            </section>
            <section class="favoris m-2 p-4">
                <h3 class=" col-10 offset-1" v-text="apiTexts['scenariosall.favoris.title']"></h3>
                <div class="d-flex justify-content-around flex-wrap col-10 offset-1">
                    <router-link v-for="scenario in apiFavoriteList" :key="scenario.id" :to="'/ScenarioOne/' + scenario.id" style="text-decoration: none;" >
                        <div class="suggestion-scenario my-4 d-flex flex-row justify-content-center align-items-center"  :style=" 'background-image: url('+scenario.image+');' ">
                            <h4 class="favori-name text-center" v-text="scenario.name"></h4>
                        </div>
                    </router-link>
                </div>
            </section>
            <section class="recherche m-2 p-4">
                <h3 class="col-10 offset-1 mb-0" v-text="apiTexts['scenariosall.recherche.title']"></h3>
            </section>
            <section class="resultat m-2 p-4">
                <div class="row col-10 offset-1">
                    <span class="col-4 d-flex flex-row align-items-center border-right border-left border-dark">
                        <i class="far fa-gem fa-2x mx-3 text-dark"></i>
                        <select class="form-control form-control-lg my-3 mr-5 text-dark" v-model="selectedType" aria-label=".form-select-lg example" style="border: none; text-decoration: none; box-shadow: none; cursor:pointer;">
                            <option selected value="">Tous</option>
                            <option v-for="item in scenarioDropdownOptions.type" :key="item" :value="item" v-text="item"></option>
                        </select>
                    </span>
                    <span class="col-4 d-flex flex-row align-items-center border-right border-dark">
                        <i class="fas fa-tachometer-alt fa-2x mx-3 text-dark"></i>
                        <select class="form-control form-control-lg my-3 mr-5 text-dark" v-model="selectedDifficulty" aria-label=".form-select-lg example" style="border: none; text-decoration: none; box-shadow: none; cursor:pointer;">
                            <option selected value="">Tous</option>
                            <option v-for="item in scenarioDropdownOptions.difficulty" :key="item" :value="item" v-text="item"></option>
                        </select>
                    </span>
                    <span class="col-4 d-flex flex-row align-items-center border-right border-dark">
                        <i class="far fa-clock fa-2x mx-3 text-dark"></i>
                        <select class="form-control form-control-lg my-3 mr-5 text-dark" v-model="selectedDuration" aria-label=".form-select-lg example" style="border: none; text-decoration: none; box-shadow: none; cursor:pointer;">
                            <option selected value="">Tous</option>
                            <option v-for="item in scenarioDropdownOptions.duration" :key="item" :value="item" v-text="item"></option>
                        </select>
                    </span>
                </div>
                <div class="col-10 offset-1 my-5 pt-5">
                    <div class="row d-flex flex-row align-items-center mb-4" v-for="scenario in displayedScenario" :key="scenario.id"  style="box-shadow: 2px 2px 20px 0px #00000010;">
                        <span class="col-lg-12 col-xl-6 p-0 pr-xl-3" style="width:100%;height:100%;">
                            <router-link v-if="scenario.id != undefined" :key="scenario.id" :to="'/ScenarioOne/' + scenario.id" style="text-decoration: none;" ><img :src="scenario.image" style="border-radius: 0px; width: 100%; height: 40vh; object-fit: cover;"></router-link>
                        </span>
                        <span class="col-lg-12 col-xl-6 p-3 ">
                            <h3 class="mb-4" v-text="scenario.name" style="font-weight:900;"></h3>
                            <div class="col-12 row mb-4 px-3">
                                <span class="my-2 p-0 row col-sm-12 col-md-4 d-flex align-items-center">
                                    <i class="far fa-gem mx-3 text-dark"></i>
                                    <p class="m-0" v-text="scenario.type"></p>
                                </span>
                                <span class="my-2 p-0 row col-sm-12 col-md-4 d-flex align-items-center">
                                    <i class="fas fa-tachometer-alt mx-3 text-dark"></i>
                                    <p class="m-0" v-text="scenario.difficulty"></p>
                                </span>
                                <span class="my-2 p-0 row col-sm-12 col-md-4 d-flex align-items-center">
                                    <i class="far fa-clock mx-3 text-dark"></i>
                                    <p class="m-0" v-text="scenario.duration"></p>
                                </span>
                            </div>
                            <div class="text-justify" v-text="scenario.preview"></div>
                            <div class="text-right mt-5">
                                <router-link v-if="scenario.id != undefined" :key="scenario.id" :to="'/ScenarioOne/' + scenario.id" style="text-decoration: none;" >En savoir plus...</router-link>
                            </div>
                        </span>
                    </div>
                </div>
                <Pagination :items="scenarioFiltered" :itemsPerPage="itemsPerPage" @contentChange="changeContent($event)"/>
            </section>
            <section class="custom-link text-center m-2 p-4">
                <h4>OU</h4>
                <h4 class=""><router-link class="" v-if="apiLinks['scenariosall.custom.scenario.link'] != undefined" :to="apiLinks['scenariosall.custom.scenario.link']" v-text="apiTexts['scenariosall.custom.scenario.link']"></router-link></h4>
            </section>
        </main>
        <Footer />
    </div>
</template>

<script>
    import apiHandler from '@/assets/scripts/apiHandler.js'

	import Nav from "@/components/Nav.vue";
	import Footer from "@/components/Footer.vue";
	import Alert from "@/components/Alert.vue";
	import Pagination from "@/components/Pagination.vue";
	
	export default {
		name: "ScenarioAll",
		components: {
			Nav,
			Footer,
			Alert,
            Pagination
		},
        data: ()=> {
            return {
                selectedType: "",
                selectedDifficulty: "",
                selectedDuration: "",
                scenarioDropdownOptions: {
                    type: [
                        "Produits",
                        "Services",
                        "Produits & services",
                    ],
                    difficulty: [
                        "Très facile",
                        "Facile",
                        "Moyen",
                        "Difficile",
                        "Très difficile",
                    ],
                    duration: [
                        "1 à 2 jours",
                        "3 jours",
                        "3 à 5 jours",
                        "Flexible",
                    ],
                },
                apiTexts: {},
                apiLinks: {},
                apiScenarioList: [],
                apiFavoriteList: [],
                scenarioFiltered: [],
                displayedScenario: [],
                itemsPerPage:4
            }
        },
        created() {
            apiHandler.getElements("texts", {page:"scenariosall"})
            .then(res => {
                this.apiTexts = {}
                res.map( item => {
                    this.apiTexts[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
            
            apiHandler.getElements("links", {page:"scenariosall"})
            .then(res => {
                this.apiLinks = {}
                res.map( item => {
                    this.apiLinks[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getSortedElements("scenario", {}, "favorite")
            .then(res => {
                this.apiFavoriteList = []

                let counter = 0

                res.map( item => {
                    if(counter < 5){
                        item.image = apiHandler.getFileURL(item.image)
                        this.apiFavoriteList.push(item)
                        counter ++
                    }
                })
                
                this.$forceUpdate()
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getSortedCollection("scenario","-id")
            .then(res => {
                res.map(item => {
                    item.image = apiHandler.getFileURL(item.image)

                })
                this.apiScenarioList = res
                this.getFilteredScenario()
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
        },
        watch: {
            selectedType(to, from) {
                this.getFilteredScenario()
            },
            selectedDifficulty(to, from) {
                this.getFilteredScenario()
            },
            selectedDuration(to, from) {
                this.getFilteredScenario()
            }
        },
        methods: {
            getFilteredScenario:function(){
                this.scenarioFiltered = []
                this.apiScenarioList.map(item => {
                    if((this.selectedType == "" || item.type == this.selectedType) && (this.selectedDifficulty == "" || item.difficulty == this.selectedDifficulty) && (this.selectedDuration == "" || item.duration == this.selectedDuration)){
                        this.scenarioFiltered.push(item)
                    }
                })
                this.$forceUpdate()
            },
            changeContent: function(items){
                this.displayedScenario = items
                this.$forceUpdate()
            },
        }
	};
</script>
