<template>
    <div>
        <header>
            <Nav />
            <Alert />
        </header>
        <main>
            <div class="m-4 px-4">
                <router-link v-if="apiLinks['scenariocustom.back'] != undefined" :to="apiLinks['scenariocustom.back']"><i class="fas fa-chevron-left fa-2x"></i></router-link>
            </div>
            <div class="d-flex flex-column mx-3">
                <section class="p-2">
                    <div class="custom-hero-header d-flex flex-column justify-content-center m-auto flex-wrap mx-2 my-0 px-3 py-0">
                        <h1 class="align-middle m-auto p-3" v-text="apiTexts['scenariocustom.hero.title']"></h1>
                        <img class="custom-img" :src="apiImages['scenariocustom.hero']" alt=""> 
                        <h4 class="w-75 mx-auto my-5" v-text="apiTexts['scenariocustom.hero.subtext']"></h4>
                    </div>
                </section>
                <section class="timeline-process d-flex flex-column m-auto p-5">
                    <div class="timeline-item">
                        <div class="timeline-img">
                            <i class="far fa-comments fa-3x py-3"></i>
                        </div>
                        <div class="timeline-content">
                            <span v-text="apiTexts['scenariocustom.timeline.first.step']"></span>
                        </div>
                    </div> 
                    <div class="timeline-item">
                        <div class="timeline-img">
                            <i class="far fa-lightbulb fa-3x py-3"></i>
                        </div>
                        <div class="timeline-content">
                            <span v-text="apiTexts['scenariocustom.timeline.second.step']"></span>
                        </div>
                    </div> 
                    <div class="timeline-item">
                        <div class="timeline-img">
                            <i class="fas fa-file-signature fa-3x py-3"></i>
                        </div>
                        <div class="timeline-content">
                            <span v-text="apiTexts['scenariocustom.timeline.third.step']"></span>
                        </div>
                    </div> 
                    <div class="timeline-item">
                        <div class="timeline-img">
                            <i class="fas fa-clipboard-check fa-3x py-3"></i>
                        </div>
                        <div class="timeline-content">
                            <span v-text="apiTexts['scenariocustom.timeline.fourth.step']"></span>
                        </div>
                    </div>  
                </section>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
    import apiHandler from '@/assets/scripts/apiHandler.js'

    import Nav from "@/components/Nav.vue";
    import Footer from "@/components/Footer.vue";
    import Alert from "@/components/Alert.vue";
    
    export default {
        name: "ScenarioCustom",
        components: {
            Nav,
            Footer,
            Alert
        },
        data: ()=> {
        return {
            apiTexts: {},
            apiImages: {},
            apiLinks: {}
            }
        },
        created() {
            apiHandler.getElements("texts", {page:"scenariocustom"})
            .then(res => {
                this.apiTexts = {}
                res.map( item => {
                    this.apiTexts[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getElements("images", {page:"scenariocustom"})
            .then(res => {
                this.apiImages = {}
                res.map( item => {
                    this.apiImages[item.path]=apiHandler.getFileURL(item.value)
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
        
            apiHandler.getElements("links", {page:"scenariocustom"})
            .then(res => {
                this.apiLinks = {}
                res.map( item => {
                    this.apiLinks[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
        }
    };
</script>
