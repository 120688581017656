<template>
    <div>
        <header>
            <Nav />
            <Alert />
        </header>
        <main>
            <div class="m-4 px-4">
                <router-link v-if="apiLinks['scenarioone.back'] != undefined" :to="apiLinks['scenarioone.back']"><i class="fas fa-chevron-left fa-2x"></i></router-link>
            </div>
            <div class="d-flex flex-column col-10 offset-1">
                <section class="mx-5 my-4 px-5">
                    <div class="d-flex flex-column justify-content-center m-3">
                        <div class="d-flex justify-content-center align-items-center" :style=" 'background-image:url(' + apiScenario.image + ');' + 'height: 35vh; background-size: cover;background-repeat: no-repeat;background-position: center; border-radius: .5em;' ">
                            <h1 class="m-5 text-center favori-name" style="font-size: 65px;" v-text="apiScenario.name"></h1>
                        </div>
                    </div>
                </section>
                <section class="d-flex flex-row-reverse justify-content-between flex-wrap mx-5 my-4 px-5">
                    <aside class="col-xl-5 offset-xl-1 col-lg-6 col-md-12">
                        <h2 v-text="apiTexts['scenarioone.caracteristiques']" class="mb-4"></h2>
                        <div class="d-flex flex-row flex-wrap">
                            <span class="ml-lg-3 my-lg-4 mb-lg-5 d-flex flex-row align-items-center col-md-4 col-lg-12">
                                <i class="far fa-gem fa-2x mx-3 text-dark"></i>
                                <b v-text="apiScenario.type"></b>
                            </span>
                            <span class="ml-lg-3 my-lg-4 mb-lg-5 d-flex flex-row align-items-center col-md-4 col-lg-12">
                                <i class="fas fa-tachometer-alt fa-2x mx-3 text-dark"></i>
                                <b v-text="apiScenario.difficulty"></b>
                            </span>
                            <span class="ml-lg-3 my-lg-4 mb-lg-5 d-flex flex-row align-items-center col-md-4 col-lg-12">
                                <i class="far fa-clock fa-2x mx-3 text-dark"></i>
                                <b v-text="apiScenario.duration"></b>
                            </span>
                        </div>
                    </aside>
                    <div class="col-lg-6 col-md-12 mt-md-5 mt-lg-0">
                        <h2 v-text="apiTexts['scenarioone.description']" class="mb-4"></h2>
                        <p v-html="apiScenario.description"></p>
                    </div>
                </section>
                <section class="d-flex flex-column mx-5 my-5 px-5">
                    <h2 v-text="apiTexts['scenarioone.linkedscenario']"></h2>
                    <div class="d-flex justify-content-around flex-wrap">
                        
                            <router-link v-for="scenario in apiScenarioList" :key="scenario.id" :to="'/ScenarioOne/' + scenario.id" style="text-decoration: none;" >
                                <div class="suggestion-scenario my-4 d-flex flex-row justify-content-center align-items-center"  :style=" 'background-image: url('+scenario.image+');' ">
                                    <h4 class="favori-name text-center" v-text="scenario.name"></h4>
                                </div>
                            </router-link>
                    </div>
                </section>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
    import apiHandler from '@/assets/scripts/apiHandler.js'

    import Nav from "@/components/Nav.vue";
    import Footer from "@/components/Footer.vue";
    import Alert from "@/components/Alert.vue";
    
    export default {
        name: "ScenarioOne",
        components: {
            Nav,
            Footer,
            Alert
        },
        data: ()=> {
            return {
                apiTexts: {},
                apiLinks: {},
                apiScenario: {},
                apiScenarioList: []
            }
        },
        created() {

            apiHandler.getElements("texts", {page:"scenarioone"})
            .then(res => {
                this.apiTexts = {}
                res.map( item => {
                    this.apiTexts[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
        
            apiHandler.getElements("links", {page:"scenarioone"})
            .then(res => {
                this.apiLinks = {}
                res.map( item => {
                    this.apiLinks[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            this.loadScenarios()
        },
        methods: {
            loadScenarios : function(){

                apiHandler.getElement("scenario", this.$route.params.id)
                .then(res => {
                    this.apiScenario = res
                    this.apiScenario.image = apiHandler.getFileURL(this.apiScenario.image)
                })
                .catch(err => {
                    console.error("⚠ Cannot get data from api !")
                    this.$router.push('/Error404')
                })

                apiHandler.getSortedElements("scenario", {}, "favorite")
                .then(res => {
                    this.apiScenarioList = []

                    let counter = 0

                    res.map( item => {
                        if(item.id != this.apiScenario.id && counter < 4){
                            item.image = apiHandler.getFileURL(item.image)
                            this.apiScenarioList.push(item)
                            counter ++
                        }
                    })
                    
                    this.$forceUpdate()

                })
                .catch(err => console.error("⚠ Cannot get data from api !"))

            }
        },
        watch: {
            $route(to, from) {
                this.loadScenarios()
            }
        }
    }
</script>