<template>
    <section class="annonces d-flex justify-content-center" v-if="apiParameters.show_alert_message">
        <p class="align-middle text-center m-auto">{{apiTexts['alert.text']}}<a class="align-middle ml-4" target="_blank" :href="apiLinks['alert.link']" v-text="apiTexts['alert.link']"></a></p>
    </section>
</template>

<script>
import apiHandler from '@/assets/scripts/apiHandler.js'

export default {
    name: "Alert",
    data: () => {
        return {
            apiParameters: {},
            apiTexts:{},
            apiLinks:{},
        };
    },
    created() {
        apiHandler.getParameters()
        .then(res => {
            this.apiParameters = res
        })
        .catch(err => console.error("⚠ Cannot get data from api !"))

        apiHandler.getElements("texts", {page:"alert"})
        .then(res => {
            this.apiTexts = {}
            res.map( item => {
                this.apiTexts[item.path]=item.value
            })
        })
        .catch(err => console.error("⚠ Cannot get data from api !"))
        
        apiHandler.getElements("links", {page:"alert"})
        .then(res => {
            this.apiLinks = {}
            res.map( item => {
                this.apiLinks[item.path]=item.value
            })
        })
        .catch(err => console.error("⚠ Cannot get data from api !"))
    }
};
</script>
