<template>
    <div>
        <header>
            <Nav/>
            <Alert/>
        </header>
        <main>
            <section class="col-8 offset-2 p-5">
                <h1 class="font-italic" v-text="apiCGU.title"></h1>

                <div class="paragraph-legal" v-html="apiCGU.content">
                </div>

            </section>
        </main>
        <Footer/>
    </div>
</template>

<script>
    import apiHandler from '@/assets/scripts/apiHandler.js'

    import Nav from "@/components/Nav.vue"
    import Footer from "@/components/Footer.vue"
    import Alert from "@/components/Alert.vue"
    
    export default {
        name: 'CGU',
        components: {
            Nav,
            Footer,
            Alert
        },
        data: ()=> {
            return {
                apiCGU: {},
            }
        },
        async created() {
            apiHandler.getCollection("cgu")
            .then(res => {
                this.apiCGU = res
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
        }

    }
</script>
