<template>
    <div>
        <header>
            <Nav/>
            <Alert/>
        </header>
        <main>
            <section class="hero-404 d-flex justify-content-center flex-wrap m-2 p-4">
                <div>
                    <h1 class="text-center">Erreur</h1>
                    <div class="w-25">
                        <img class="img-erreur-404" :src="apiImages['erreur404.hero']" alt="#">
                    </div>
                </div>
                <div class="d-flex flex-column align-center align-middle w-50 mt-auto mb-auto ">
                    <p class="text-center w-75 m-auto" v-text="apiTexts['erreur404.paragraphtext']"></p>
                    <span class="m-auto">
                        <button class="btn-big btn-big-primary mr-5 mt-3">
                            <router-link class="btn-link"  v-if="apiLinks['erreur404.button.scenario.all'] != undefined" :to="apiLinks['erreur404.button.scenario.all']" v-text="apiTexts['erreur404.button.scenario.all']"></router-link>
                        </button>
                        <button class="btn-big btn-big-secondary mt-3">
                            <router-link class="btn-link"  v-if="apiLinks['erreur404.button.home'] != undefined" :to="apiLinks['erreur404.button.home']" v-text="apiTexts['erreur404.button.home']"></router-link>
                        </button>
                    </span>
                </div>
            </section>
        </main>
        <Footer/>
    </div>
</template>

<script>
    import apiHandler from '@/assets/scripts/apiHandler.js'
    
    import Nav from "@/components/Nav.vue"
    import Footer from "@/components/Footer.vue"
    import Alert from "@/components/Alert.vue"
    
    export default {
        name: 'Erreur404',
        components: {
            Nav,
            Footer,
            Alert
        },
        data: ()=>{
            return {
            apiTexts: {},
            apiLinks: {},
            apiImages: {}
            }
        },
        async created() {
            apiHandler.getElements("texts", {page:"erreur404"})
            .then(res => {
                this.apiTexts = {}
                res.map( item => {
                    this.apiTexts[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
            
            apiHandler.getElements("links", {page:"erreur404"})
            .then(res => {
                this.apiLinks = {}
                res.map( item => {
                    this.apiLinks[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getElements("images", {page:"erreur404"})
            .then(res => {
                this.apiImages = {}
                res.map( item => {
                    this.apiImages[item.path]=apiHandler.getFileURL(item.value)
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
        }
    }
</script>
