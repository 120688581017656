<template>
    <footer class="m-2 p-4">
        <hr>
        <section class="d-flex flex-row justify-content-between flex-wrap pt-5">
            <div>
                <p><i class="fas fa-envelope mr-2"></i>{{apiTexts['contact.infos.mail']}}</p>
                <p><i class="fas fa-map-marker-alt mr-2"></i>{{apiTexts['contact.infos.adress']}}</p>
                <p><i class="fab fa-linkedin mr-2"></i><a target="_blank" :href="apiLinks['contact.contactform.linkedin']" v-text="apiTexts['contact.contactform.linkedin']"></a></p>
            </div>
            <div class="footer-right">
                <p><router-link v-if="apiLinks['contact.cgu'] != undefined" :to="apiLinks['contact.cgu']" v-text="apiTexts['contact.cgu']"></router-link></p>
                <p>©{{currentYear}} Copyrights all rights</p>
            </div>
        </section>
    </footer>
</template>

<script>

import apiHandler from '@/assets/scripts/apiHandler.js'

export default {
    name: "Footer",
    data: () => {
        return {
            currentYear:0,
            apiTexts: {},
            apiLinks: {}
        }
    },
    created() {
        this.currentYear = new Date().getFullYear()

        apiHandler.getElements("texts", {page:"contact"})
        .then(res => {
            this.apiTexts = {}
            res.map( item => {
                this.apiTexts[item.path]=item.value
            })
        })
        .catch(err => console.error("⚠ Cannot get data from api !"))
        
        apiHandler.getElements("links", {page:"contact"})
        .then(res => {
            this.apiLinks = {}
            res.map( item => {
                this.apiLinks[item.path]=item.value
            })
        })
        .catch(err => console.error("⚠ Cannot get data from api !"))
    },
};
</script>
