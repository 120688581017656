<template>
    <div v-if="pageAmount > 1" class="d-flex flex-row align-items-center justify-content-center">
        <span class="mx-2" @click="previousPage">
            <i v-if="currentPage == 1" class="fas fa-chevron-left text-secondary"></i>
            <i v-else class="fas fa-chevron-left text-dark" style="cursor:pointer;"></i>
        </span>
        <span class="mx-2 text-primary" v-for="index in pageAmount" :key="index" @click="goToPage(index)" >
            <h5 v-if="currentPage == index" class="text-primary " v-text="index" style="cursor:pointer;"></h5>
            <h5 v-else class="text-dark " v-text="index" style="cursor:pointer;"></h5>
        </span>
        <span class="mx-2" @click="nextPage">
            <i v-if="currentPage >= pageAmount" class="fas fa-chevron-right text-secondary"></i>
            <i v-else class="fas fa-chevron-right text-dark" style="cursor:pointer;"></i>
        </span>
    </div>
</template> 

<script>
    export default {
        name: "Pagination",
        props:["items", "itemsPerPage"],
        data: () => {
            return {
                currentPage:1
            };
        },
        mounted(){
            this.goToPage(this.currentPage)
        },
        computed:{
            pageAmount: function(){
                return Math.ceil(this.items.length/this.itemsPerPage)
            },
            currentDisplayedItems: function(){
                let tmp = []
                for(let i = 0; i < this.itemsPerPage; i++){
                    if(this.items[i + ((this.currentPage-1) * this.itemsPerPage)] != undefined){
                        tmp.push(this.items[i + ((this.currentPage-1) * this.itemsPerPage)])
                    }   
                }
                return tmp
            }
        },
        watch: {
            currentPage(to, from) {
                if(to > this.pageAmount || to < 1){
                    this.currentPage = from
                }
            },
            currentDisplayedItems(to, from) {
                this.$emit('contentChange', this.currentDisplayedItems)
            }
        },
        methods: {
            goToPage: function(index){
                if(index <= this.pageAmount && index >= 1){
                    this.currentPage = index
                }
            },
            previousPage: function(){
                let index = this.currentPage - 1
                if(index <= this.pageAmount && index >= 1){
                    this.currentPage = index
                }
            },
            nextPage: function(){
                let index = this.currentPage + 1
                if(index <= this.pageAmount && index >= 1){
                    this.currentPage = index
                }
            }
        },
    };
</script>