import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import CGU from '../views/CGU.vue'
import Erreur404 from '../views/Erreur404.vue'
import About from '../views/About'
import ActusAll from '../views/ActusAll.vue'
import ActuOne from '../views/ActuOne.vue'
import ScenarioAll from '../views/ScenarioAll.vue'
import ScenarioOne from '../views/ScenarioOne.vue'
import ScenarioCustom from '../views/ScenarioCustom.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/CGU',
        name: 'CGU',
        component: CGU
    },
    {
        path: '/About',
        name: 'About',
        component: About
    },
    {
        path: '/ActusAll',
        name: 'ActusAll',
        component: ActusAll
    },
    {
        path: '/ActuOne/:id',
        name: 'ActuOne',
        component: ActuOne
    },
    {
        path: '/ScenarioAll',
        name: 'ScenarioAll',
        component: ScenarioAll
    },
    {
        path: '/ScenarioOne/:id',
        name: 'ScenarioOne',
        component: ScenarioOne
    },
    {
        path: '/ScenarioCustom',
        name: 'ScenarioCustom',
        component: ScenarioCustom
    },
    {
        path: '/Contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '*',
        name: 'Erreur404',
        component: Erreur404
    }
]

const router = new VueRouter({
    mode: "history",
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
})

export default router