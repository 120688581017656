<template>
    <div>
        <header>
            <Nav/>
            <Alert/>
        </header>
        <main>
            <section class="hero-accueil d-flex justify-content-center flex-wrap m-2 p-4"> 
                <div class="hero-text align-middle mt-auto mb-auto">
                    <h1 v-text="apiTexts['home.hero.title']"></h1>
                    <h3 v-text="apiTexts['home.hero.subtitle']"></h3>
                    <div class="d-flex justify-content-center">
                        <a class="btn-link mr-4" target="_blank" :href="apiLinks['home.hero.button.left']">
                            <button class="btn-big btn-big-primary mt-3" id="jeu-gestion" style="height: 4rem; width: 24rem;text-decoration: none;">
                                <a class="btn-link" target="_blank" :href="apiLinks['home.hero.button.left']" v-text="apiTexts['home.hero.button.left']" style="font-size: 1.6rem;text-decoration: none;"></a>
                            </button>
                        </a>
                        <a class="btn-link mr-4" target="_blank" :href="apiLinks['home.hero.button.right']">
                            <button class="btn-big btn-big-blue mt-3" id="jeu-gestion" style="height: 4rem; width: 24rem;text-decoration: none;">
                                <a class="btn-link" target="_blank" :href="apiLinks['home.hero.button.right']" v-text="apiTexts['home.hero.button.right']" style="font-size: 1.6rem;text-decoration: none;"></a>
                            </button>
                        </a>
                    </div>
                </div>
                <div>
                    <div class="hero-img">
                        <img class="accueil-hero-img" :src="apiImages['home.hero']" alt="#">
                    </div>
                </div>
            </section>
            <section class="chiffres d-flex justify-content-around flex-wrap m-2 p-4">
                <div class="chiffre-item mt-3">
                    <i class="fas fa-school fa-3x mb-3" alt="#"></i>
                    <h3 v-text="apiTexts['home.numbers.schools.title']"></h3>
                    <h4 v-text="apiTexts['home.numbers.schools.subtitle']"></h4>
                </div>
                <div class="chiffre-item mt-3">
                    <i class="fas fa-scroll fa-3x mb-3" alt="#"></i>
                    <h3 v-text="apiTexts['home.numbers.scenario.title']"></h3>
                    <h4 v-text="apiTexts['home.numbers.scenario.subtitle']"></h4>
                </div>
                <div class="chiffre-item mt-3">
                    <i class="fas fa-laptop fa-3x mb-3" alt="#"></i>
                    <h3 v-text="apiTexts['home.numbers.simulation.title']"></h3>
                    <h4 v-text="apiTexts['home.numbers.simulation.subtitle']"></h4>
                </div>
            </section>
            <section class="concept d-flex justify-content-center flex-wrap m-2 p-4">
                <div class="m-auto">
                    <img class="img-concept" :src="apiImages['home.concept']" alt="#">
                </div>
                <div class="concept-text m-auto text-center align-middle">
                    <h3 class="" v-text="apiTexts['home.concept.title']"></h3>
                    <p class="" id="" v-text="apiTexts['home.concept.text']"></p>
                </div>
            </section>
            <section class="simulations d-flex flex-column justify-content-center flex-wrap m-2 p-4">
                <h3 class="text-center"  v-text="apiTexts['home.simulation.title']"></h3>
                <div class="d-flex justify-content-center flex-wrap mb-4">
                    <div class="simulation-item m-2 p-5">
                        <img class="simulation-img" :src="apiImages['home.simulation.cards.left']" alt="#">
                        <h4 v-text="apiTexts['home.simulation.cards.left.title']"></h4>
                        <p v-text="apiTexts['home.simulation.cards.left.text']"></p>
                    </div>
                    <div class="simulation-item m-2 p-5">
                        <img class="simulation-img" :src="apiImages['home.simulation.cards.right']" alt="#">
                        <h4 v-text="apiTexts['home.simulation.cards.right.title']"></h4>
                        <p v-text="apiTexts['home.simulation.cards.right.text']"></p>
                    </div>
                </div>
                <button class="btn-medium-primary m-auto"><router-link v-if="apiLinks['home.simulation.button'] != undefined" :to="apiLinks['home.simulation.button']" v-text="apiTexts['home.simulation.button']"></router-link></button>
            </section>
            <section class="partenaires d-flex flex-column justify-content-center m-3 p-4">
                <h3 class="text-center" v-text="apiTexts['home.partners.title']"></h3>
                <vueper-slides class="no-shadow w-75 m-auto" autoplay :duration="8000" :transition-speed="1000" :visible-slides="5" :slide-multiple="true" :gap="3" :slide-ratio="1 / 15" fixed-height="100px" :dragging-distance="200" :breakpoints="{ 800: { visibleSlides: 2}, 950: { visibleSlides: 3}, 1200: { visibleSlides: 4}}">
                    <vueper-slide v-for="partner in apiPartners" :key="partner.name" :image="partner.image" :link="partner.link" :openInNew="true"/>
                </vueper-slides>
            </section>
        </main>
        <Footer/>
    </div>
</template>

<script>
import apiHandler from '@/assets/scripts/apiHandler.js'

import Nav from "@/components/Nav.vue"
import Footer from "@/components/Footer.vue"
import Alert from "@/components/Alert.vue"

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'


export default {
    name: 'Home',
    components: {
        Nav,
        Footer,
        Alert,
        VueperSlides,
        VueperSlide
    },
    data: ()=> {
        return {
            apiTexts: {},
            apiLinks: {},
            apiImages: {},
            apiPartners: []
        }
    },
    async created() {
        apiHandler.getElements("texts", {page:"home"})
        .then(res => {
            this.apiTexts = {}
            res.map( item => {
                this.apiTexts[item.path]=item.value
            })
        })
        .catch(err => console.error("⚠ Cannot get data from api !"))
        
        apiHandler.getElements("links", {page:"home"})
        .then(res => {
            this.apiLinks = {}
            res.map( item => {
                this.apiLinks[item.path]=item.value
            })
        })
        .catch(err => console.error("⚠ Cannot get data from api !"))
        
        apiHandler.getElements("images", {page:"home"})
        .then(res => {
            this.apiImages = {}
            res.map( item => {
                this.apiImages[item.path]=apiHandler.getFileURL(item.value)
            })
        })
        .catch(err => console.error("⚠ Cannot get data from api !"))
        
        apiHandler.getCollection("partners")
        .then(res => {
            res.map(item => {
                item.image = apiHandler.getFileURL(item.image)
            })
            this.apiPartners = res
        })
        .catch(err => console.error("⚠ Cannot get data from api !"))
    }
}
</script>
