<template>
    <div>
        <header>
            <Nav/>
            <Alert/>
        </header>
        <main>
            <section class="d-flex flex-column justify-content-between m-auto mt-3 p-4 mb-5">
                <h1 class="hero-actus-heading" v-text="apiTexts['actus.hero.heading']"></h1>
                <div class="d-flex flex-wrap pb-5 mb-5 justify-content-center">
                    <img class="col-lg-6 col-xl-5 img-actu-hero" :src="apiActuFeatured.image" alt="">
                    <div class="col-lg-6 col-xl-5 hero-actus-text d-flex flex-column align-center align-middle my-auto">
                        <h6 class="text-left mx-0" v-text="apiActuFeatured.date"></h6>
                        <h2 v-text="apiActuFeatured.title"></h2>
                        <p class="" v-text="apiActuFeatured.preview"></p>
                        <button class="btn-big btn-big-primary"><router-link v-if="apiLinks['actuone.btn'] != undefined" :to="apiLinks['actuone.btn']+apiActuFeatured.id" v-text="apiTexts['actus.hero.featured.btn']"></router-link></button>
                    </div>
                </div>

                <div class="select-sort-container">
                    <div class="select-label-sort"><b>Trier par : </b></div>
                    <select class="select-sort" v-model="selected" @change="onChange($event)" style="cursor:pointer; display: inherit; width: inherit;">
                        <option value="date">Date la plus récente</option>
                        <option value="alphabetical">Ordre alphabétique</option>
                    </select>
                </div>

                <div id="all-actus">
                    <div class="grid-one-actu text-center" v-for="actuOne in displayedActus" :key="actuOne.id">
                        <router-link v-if="apiLinks['actuone.btn'] != undefined" :to="apiLinks['actuone.btn']+apiActuFeatured.id">
                            <img class="img-grid-one-actu" :src="actuOne.image" alt="">
                        </router-link>
                        
                        <div class="hero-actus-text text-left my-auto">
                            <h6 class="text-left my-3 mx-0" v-text="actuOne.date"></h6>
                            <h3 v-text="actuOne.title"></h3>
                            <p class="text-justify" v-text="actuOne.preview"></p>
                            <router-link v-if="apiLinks['actuone.btn'] != undefined" :to="apiLinks['actuone.btn']+actuOne.id" v-text="apiTexts['actus.hero.featured.btn']"></router-link>
                        </div>
                    </div>
                </div>
            </section>
            <Pagination :items="apiAllActus" :itemsPerPage="8" @contentChange="changeContent($event)" />
        </main>
        <Footer/>
    </div>
</template>

<script>
    import Nav from "@/components/Nav.vue"
    import Footer from "@/components/Footer.vue"
    import Alert from "@/components/Alert.vue"
    import Pagination from "@/components/Pagination.vue"

    import apiHandler from '@/assets/scripts/apiHandler.js'

    export default {
        name: 'ActusAll',
        components: {
            Nav,
            Footer,
            Alert,
            Pagination
        },
        data: ()=> {
            return {
                apiTexts: {},
                apiLinks: {},
                apiImages: {},
                apiAllActus: [],
                apiActuFeatured : {},
                copyApiAllActus : [],
                selected: 'date',
                displayedActus : []
            }
        },
        async created() {
            apiHandler.getElements("texts", {page:"actus"})
            .then(res => {
                this.apiTexts = {}
                res.map( item => {
                    this.apiTexts[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
            
            apiHandler.getElements("images", {page:"actus"})
            .then(res => {
                this.apiImages = {}
                res.map( item => {
                    this.apiImages[item.path]=apiHandler.getFileURL(item.value)
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getElements("links", {page:"actuone"})
            .then(res => {
                this.apiLinks = {}
                res.map( item => {
                    this.apiLinks[item.path]=item.value
                })
            })
        .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getSortedCollection("actus","date")
            .then(res => {
                res.map( item => {
                    item.image = apiHandler.getFileURL(item.image)
                })
                this.apiAllActus = res.reverse()
                this.apiActuFeatured = res[0]

            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
            
        },
        methods : {
            onChange : function (event) {
                if(event.target.value == "date"){
                    this.apiAllActus = this.apiAllActus.sort(function(a, b){
                        let dateA = Date.parse(a.date)/1000
                        let dateB = Date.parse(b.date)/1000
                        return dateB - dateA;
                    })
                }
                else if(event.target.value == "alphabetical"){
                    this.apiAllActus = this.apiAllActus.sort((a,b)=>{
                        const textA = a.title.toUpperCase()
                        const textB = b.title.toUpperCase() 
                        if (textA < textB) {
                            return -1
                        }
                        if (textA > textB) {
                            return 1
                        }
                        return 0
                    })
                }
                
            },

            changeContent : function (items) {
                this.displayedActus = items
                this.$forceUpdate()
            }
        }
    }
</script>
