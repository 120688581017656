import axios from 'axios'

let config = {
    apiUrl: "https://sitebeta.ludimation.com",
    accessToken: "d92966f5149caaaf761faa1c2356075fd829817578d7d07ef8ce48273922e4f0"
}

export default {
    getCollection : async(collectionName) => {
        let res
        try{
            res = await axios.get(config.apiUrl + "/items/"+collectionName+"?access_token="+config.accessToken)
        }
        catch (err){
            throw err;
        }
        
        return res.data.data
    },

    getSortedCollection : async(collectionName,sortedBy) => {
        let res
        try{
            res = await axios.get(config.apiUrl + "/items/"+collectionName+"?access_token="+config.accessToken+"&sort[]="+sortedBy)
        }
        catch (err){
            throw err;
        }
        
        return res.data.data
    },

    getElement : async(collectionName, elementPrimaryKey) => {
        let res

        try{
            res = await axios.get(config.apiUrl + "/items/"+collectionName+"/"+elementPrimaryKey+"?access_token="+config.accessToken)
        }
        catch (err){
            throw err;
        }
        
        return res.data.data
    },

    getElements : async(collectionName, filters) => {
        let res

        let filter = ""

        if(Object.keys(filters).length > 0){
            filter += "{\"_and\":["
            for(let key in filters){
                filter += "{\""+key+"\":{\"_eq\":\""+filters[key]+"\"}},"
            }
            filter = filter.slice(0, -1)
            filter += "]}";
        }

        try{
            res = await axios.get(config.apiUrl + "/items/"+collectionName+"?access_token="+config.accessToken+"&filter="+filter)
        }
        catch (err){
            throw err;
        }
        
        return res.data.data
    },

    getSortedElements : async(collectionName, filters, sortedBy) => {
        let res
        
        let filter = ""

        if(Object.keys(filters).length > 0){
            filter += "{\"_and\":["
            for(let key in filters){
                filter += "{\""+key+"\":{\"_eq\":\""+filters[key]+"\"}},"
            }
            filter = filter.slice(0, -1)
            filter += "]}";
        }

        try{
            res = await axios.get(config.apiUrl + "/items/"+collectionName+"?access_token="+config.accessToken+"&filter="+filter+"&sort[]="+sortedBy)
        }
        catch (err){
            throw err;
        }
        
        return res.data.data
    },

    getFileURL : (fileId) => {
        return config.apiUrl + "/assets/"+fileId+"?access_token="+config.accessToken
    },

    getParameters : async() => {
        let res

        try{
            res = await axios.get(config.apiUrl + "/items/parameters?access_token="+config.accessToken)
        }
        catch (err){
            throw err;
        }
        
        return res.data.data
    }

    
}