<template>
    <div>
        <header>
            <Nav/>
            <Alert/>
        </header>
        <main>
            <section class="d-flex justify-content-center m-3 p-4 flex-wrap">
                <div class="about-titre-hero">
                    <h1 class="align-middle m-auto" v-text="apiTexts['about.hero.heading']"></h1>
                </div>
                <div class="m-auto">
                    <img class="img-qui-sommes-nous" :src="apiImages['about.hero.img']" alt="">
                    <legend v-text="apiTexts['about.legend.img']"></legend>
                </div>
            </section>
            <section class="chronologie mx-0 my-3 p-4">
                <div class="timeline d-flex text-center m-auto">
                    <div v-for="timeline in apiTimeline" :key="timeline.year" class="timeline-slide my-4">
                        <div class="timestamp">
                            <h2 v-text="timeline.year"></h2>
                        </div>
                        <div class="time-status">
                            <span><h4 v-text="timeline.description"></h4></span>
                        </div>
                    </div>
                </div>
            </section >
            <section class="text-center objectif m-3 p-4">
                <div class="objectif-container m-auto">
                    <h3 v-text="apiTexts['about.objectif.title']"></h3>
                    <p v-text="apiTexts['about.objectif.paragraphtext']"></p>
                </div>
            </section>
            <section class="m-3 p-4">
                <h3 class="mb-3" v-text="apiTexts['about.experts.title']"></h3>
                <p class="experts-subtitle" v-text="apiTexts['about.experts.subtitle']"></p>
                <div class="d-flex flex-wrap justify-content-around m-2 p-4" id="experts">
                    <div v-for="members in apiMembers" :key="members.id" class="expert-item">
                        <img class="img-expert" :src="members.image" alt="">
                        <h5 v-text="members.name"></h5>
                        <h6 v-text="members.role"></h6>
                        <span v-text="members.description" ></span>
                    </div>
                </div>
            </section>
        </main>
        <Footer/>
    </div>
</template>

<script>

    import apiHandler from '@/assets/scripts/apiHandler.js'

    import Nav from "@/components/Nav.vue"
    import Footer from "@/components/Footer.vue"
    import Alert from "@/components/Alert.vue"
    
    export default {
        name: 'About',
        components: {
            Nav,
            Footer,
            Alert
        },
        data: ()=> {
            return {
                apiTexts: {},
                apiLinks: {},
                apiImages: {},
                apiTimeline: [],
                apiMembers : []
            }
        },
        created() {
            apiHandler.getElements("texts", {page:"about"})
            .then(res => {
                this.apiTexts = {}
                res.map( item => {
                    this.apiTexts[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getElements("images", {page:"about"})
            .then(res => {
                this.apiImages = {}
                res.map( item => {
                    this.apiImages[item.path]=apiHandler.getFileURL(item.value)
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getCollection("timeline")
            .then(res => {
                let tmpTimeline = res
                tmpTimeline = tmpTimeline.sort((a, b) => a.order < b.order ? -1 : 1)
                this.apiTimeline = tmpTimeline
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getCollection("members")
            .then(res => {
                res.map( item => {
                    item.image = apiHandler.getFileURL(item.image)
                })
                this.apiMembers = res
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))
        }
    }
</script>
