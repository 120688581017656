<template>
    <div>
        <header>
            <Nav/>
            <Alert/>
        </header>
        <main>
            <div class="m-4 px-4">
                <router-link v-if="apiLinks['actuone.back'] != undefined" :to="apiLinks['actuone.back']"><i class="fas fa-chevron-left fa-2x"></i></router-link>
            </div>
            <section class="d-flex flex-wrap justify-content-center m-3 align-items-center mb-5">
                <div class="col-lg-12 col-xl-4 text-center my-auto"><img class="img-actu-one-hero" :src="apiActuOne.image" alt=""></div>
                <div class="hero-actus-text col-lg-12 col-xl-6">
                    <h5 class="text-left mx-0" v-text="apiActuOne.date"></h5>
                    <h1 v-text="apiActuOne.title"></h1>
                </div>               
            </section>

            <section class="col-10 offset-1 p-4 ">
                <div class="mt-5" v-html="apiActuOne.content" id="article-content"></div>
            </section>
        </main>
        <Footer/>
    </div>
</template>

<script>
    import Nav from "@/components/Nav.vue"
    import Footer from "@/components/Footer.vue"
    import Alert from "@/components/Alert.vue"


    import apiHandler from '@/assets/scripts/apiHandler.js'

    export default {
        name: 'ActusAll',
        components: {
            Nav,
            Footer,
            Alert
        },
        data: ()=> {
            return {
                apiLinks: {},
                apiActuOne: {}
            }
        },
        created() {
            apiHandler.getElements("texts", {page:"actuone"})
            .then(res => {
                this.apiTexts = {}
                res.map( item => {
                    this.apiTexts[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getElements("images", {page:"actuone"})
            .then(res => {
                this.apiImages = {}
                res.map( item => {
                    this.apiImages[item.path]=apiHandler.getFileURL(item.value)
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getElements("links", {page:"actuone"})
            .then(res => {
                this.apiLinks = {}
                res.map( item => {
                    this.apiLinks[item.path]=item.value
                })
            })
            .catch(err => console.error("⚠ Cannot get data from api !"))

            apiHandler.getElement("actus",this.$route.params.id)
            .then(res => {
                res.image = apiHandler.getFileURL(res.image)
                this.apiActuOne = res
            })
        }
    }
</script>
